import * as React from "react";
import styled from "@emotion/styled";

import IFrameAuth from "../../components/app/IFrameAuth";
import { LoginFormStrings } from "../../components/strings/LoginFormStrings";
import { LoginForm, LoginFooter } from "@fitplan/lib/components/UserAuth/LoginForm";

export interface Props {}

const Login: React.SFC<Props> = (props) => {
    return (
        <Container>
            <LoginFormStrings>
                <LoginForm onComplete={(user) => {}} darkMode />
                <LoginFooter link="/app/signup" darkMode />
            </LoginFormStrings>
            <IFrameAuth />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: black;
`;

export default Login;
